<template>
  <div class="card">
    <div class="card-header">
      <h3>Conditions Counts Per Location and Tool</h3>

      <div class="legends">
        <div class="item" v-if="hasPermission('conditions','malaria')">
          <div class="box malaria"></div>
          <div class="label">Malaria</div>
        </div>
        <div class="item" v-if="hasPermission('conditions','tuberculosis')">
          <div class="box tb"></div>
          <div class="label">Tuberculosis</div>
        </div>
        <div class="item" v-if="hasPermission('conditions','hiv')">
          <div class="box hiv"></div>
          <div class="label">HIV</div>
        </div>
      </div>
    </div>
    <div class="content">

      <div class="chart-container">
        <canvas id="usersChart"></canvas>
      </div>
      <div class="dropdown-container">
        <div class="container">
          <select v-model="selectedTool" @change="fetchChartData">
            <option disabled value="">Select Tool</option>
            <option value="1" v-if="hasPermission('tools','patient_tool')">Patient Tool</option>
            <option value="2" v-if="hasPermission('tools','health_facility_tool')">Health Facility</option>
            <option value="3" v-if="hasPermission('tools','community_based_tool')">Community Based</option>
            <option value="4" v-if="hasPermission('tools','observation_tool')">Observation Tool</option>
          </select>

          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <div class="container">
          <select v-model="selectedProvince" @change="fetchDistricts">
            <option disabled value="">Select Province</option>
            <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
          </select>

          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <div class="container">
          <select v-model="selectedDistrict" @change="fetchChartData">
            <option disabled value="">Select District</option>
            <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
          </select>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      provinces: [],
      districts: [],
      selectedTool: "",
      selectedProvince: "",
      selectedDistrict: "",
      chart: null,
    };
  },
  mounted() {
    this.fetchProvinces();
    this.fetchChartData();
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  methods: {
    async fetchProvinces() {
      try {
        const response = await axios.get('/province');
        this.provinces = response.data.data;
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(`/districts/${this.selectedProvince}`);
        this.districts = response.data.data;
        this.fetchChartData();
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchChartData() {
      try {
        const response = await axios.get('chart-data', {
          params: {
            tool_id: this.selectedTool,
            province_id: this.selectedProvince,
            district_id: this.selectedDistrict,
          },
        });
        const percentages = response.data.data;
        this.updateChart(percentages);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    },
    updateChart(percentages) {
      const ctx = document.getElementById("usersChart").getContext("2d");

      let delayed;

      // let firstGradient = ctx.createLinearGradient(0, 0, 0, 400);
      // firstGradient.addColorStop(0, "rgba(58, 123, 213, 1)");
      // firstGradient.addColorStop(1, "rgba(0, 210, 255, 0.3)");
      // let secondGradient = ctx.createLinearGradient(0, 0, 0, 100);
      // secondGradient.addColorStop(0, "#08b35d");
      // secondGradient.addColorStop(1, "#23921694");

      let firstGradient = ctx.createLinearGradient(0, 0, 0, 500);
      firstGradient.addColorStop(0, "rgba(255, 0, 0, 1)");
      firstGradient.addColorStop(1, "rgba(255, 0, 0, 0.3)");

      let secondGradient = ctx.createLinearGradient(0, 0, 0, 400);
      secondGradient.addColorStop(0, "rgba(58, 123, 213, 1)");
      secondGradient.addColorStop(1, "rgba(0, 210, 255, 0.3)");

      let thirdGradient = ctx.createLinearGradient(0, 0, 0, 100);
      thirdGradient.addColorStop(0, "black");
      thirdGradient.addColorStop(1, "#000000ba");

      const labels = ["HIV", "Malaria", "Tuberculosis"];

      const data = {
        labels,
        datasets: [
          {
            data: [percentages['HIV'], percentages['Malaria'], percentages['Tuberculosis']],
            label: "Participants",
            fill: true,
            backgroundColor: [firstGradient, secondGradient, thirdGradient],
            borderColor: "#fff",
            pointBackgroundColor: "rgba(189, 195, 199, 0.4)",
            tension: 0,
          },
        ],
      };

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: {
          responsive: true,
          animation: {
            onComplete: () => {
              delayed = true;
            },
            delay: (context) => {
              let delay = 0;
              if (
                context.type === "data" &&
                context.mode === "default" &&
                !delayed
              ) {
                delay = context.dataIndex * 300 + context.datasetIndex * 100;
              }
              return delay;
            },
          },
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              display: false,
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 455px;
  height: 345px;
  box-shadow: 0 0 14px rgb(206, 201, 201);
  background-color: white;
  border-radius: 15px;
  padding: 24px;

  .card-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    display: flex;
    gap: 12px;
    padding-bottom: 24px;



  h3 {
    font-weight: 400;
    font-size: 16;
    line-height: 24px;
  }
    .legends {
      display: flex;
      align-items: center;
      gap: 13px;

      .item {
        display: flex;
        align-items: center;
        gap: 6px;

        .label {
          color: #667085;
        }

      }

      .box {
        width: 8px;
        height: 8px;
        border-radius: 50px;
      }

      .hiv {
        background-color: red;
      }

      .tb {
        background-color: black;
      }

      .malaria {
        background-color: rgba(58, 123, 213, 1);
      }
    }
  }

  .content {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .chart-container {
    width: 200px;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    select {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      appearance: none;
      background-color: transparent;
      color: #39325a;
    }

  }
}

.container {
  position: relative;
  width: 100%;



  svg {
    position: absolute;
    top: 60%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

select {

  margin-top: 10px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  width: 190px;
  font-size: 1em;
  outline: none;
  padding-left: 16px;
  background-color: transparent;
  color: #39325a;


}
</style>
