<template>
  <div class="card">
    <div class="card-header">
      <h3>Resolved Cases Count Per Tool, Condition and Location</h3>
    </div>
    <div class="content">
      <div class="chart-container">
        <canvas id="CasesChart"></canvas>
      </div>
      <div class="dropdown-container">
        <select v-model="selectedTool" @change="fetchChartData">
          <option disabled value="">Select Tool</option>
          <option value="1" v-if="hasPermission('tools', 'patient_tool')">Patient Tool</option>
          <option value="2" v-if="hasPermission('tools', 'health_facility_tool')">Health Facility</option>
          <option value="3" v-if="hasPermission('tools', 'community_based_tool')">Community Based</option>
          <option value="4" v-if="hasPermission('tools', 'observation_tool')">Observation Tool</option>
        </select>
        <select v-model="selectedCondition" @change="fetchChartData">
          <option disabled value="">Select Condition</option>
          <option value="1" v-if="hasPermission('conditions', 'hiv')">HIV</option>
          <option value="2" v-if="hasPermission('conditions', 'malaria')">Malaria</option>
          <option value="3" v-if="hasPermission('conditions', 'tuberculosis')">Tuberculosis</option>
        </select>
        <select v-model="selectedProvince" @change="fetchDistricts">
          <option disabled value="">Select Province</option>
          <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
        </select>
        <select v-model="selectedDistrict" @change="fetchChartData">
          <option disabled value="">Select District</option>
          <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      provinces: [],
      districts: [],
      selectedTool: "",
      selectedCondition: "",
      selectedProvince: "",
      selectedDistrict: "",
      chart: null,
    };
  },
  mounted() {
    this.fetchProvinces();
    this.fetchChartData(); // Fetch data when the component is mounted
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  methods: {
    async fetchProvinces() {
      try {
        const response = await axios.get('/province');
        this.provinces = response.data.data;
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(`/districts/${this.selectedProvince}`);
        this.districts = response.data.data;
        this.fetchChartData(); // Fetch data when a province is selected
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchChartData() {
      try {
        const response = await axios.get('/resolved-cases', {
          params: {
            tool_id: this.selectedTool,
            province_id: this.selectedProvince,
            district_id: this.selectedDistrict,
            condition_id: this.selectedCondition,
          },
        });

        const chartData = response.data.data;
        const labels = chartData.map(item => item.date);
        const data = chartData.map(item => item.count);

        this.updateChart(labels, data);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    },
    updateChart(labels, data) {
      if (this.chart) {
        this.chart.destroy(); // Destroy the existing chart to update it
      }

      const ctx = document.getElementById("CasesChart").getContext("2d");

      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Resolved Cases",
              data: data,
              fill: true,
              backgroundColor: "rgba(58, 123, 213, 0.2)",
              borderColor: "rgba(58, 123, 213, 1)",
              tension: 0.4,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                display: false, // Remove y-axis grid lines
              },
            },
            x: {
              grid: {
                display: true, // Remove x-axis grid lines
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 600px;
  /* Increase the overall height of the card */
  box-shadow: 0 0 14px rgb(206, 201, 201);
  background-color: white;
  border-radius: 15px;
  padding: 20px;

  .card-header {
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 85%;
  }

  .chart-container {
    width: 75%;
    /* Increase the width of the chart container */
    height: 500px;
    /* Increase the height of the chart container */
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    canvas {
      width: 100%;
      height: 100%;
      /* Ensure the canvas takes up the full height */
    }
  }

  .dropdown-container {
    width: 20%;
    /* Reduce the width of the dropdown container */
    display: flex;
    flex-direction: column;
    gap: 10px;

    select {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
}
</style>
