<template>
  <div class="dashboard-container">
    <div class="row">
      <div class="col">
        <!-- <h2>Hi {{ username }}</h2> -->
        <h2>Total Surveys done Vs Location</h2>
      </div>
    </div>
    <div class="row cards-row">
      <!-- Patient Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'patient_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Patient Tool</p>
              <span class="main-count">
                <p class="count">{{ patientToolCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="patientToolProvince" @change="fetchDistrictsAndCount('patientTool', 1)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="patientToolDistrict" @change="fetchCount('patientTool', 1)">
                <option disabled value="">Select District</option>
                <option v-for="district in patientToolDistricts" :key="district.id" :value="district.id">{{
        district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Community Based Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'community_based_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Community Based</p>
              <span class="main-count">
                <p class="count">{{ communityBasedCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="communityBasedProvince" @change="fetchDistrictsAndCount('communityBased', 3)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="communityBasedDistrict" @change="fetchCount('communityBased', 3)">
                <option disabled value="">Select District</option>
                <option v-for="district in communityBasedDistricts" :key="district.id" :value="district.id">{{
        district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Health Facility Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'health_facility_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Health Facility</p>
              <span class="main-count">
                <p class="count">{{ healthFacilityCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="healthFacilityProvince" @change="fetchDistrictsAndCount('healthFacility', 2)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="healthFacilityDistrict" @change="fetchCount('healthFacility', 2)">
                <option disabled value="">Select District</option>
                <option v-for="district in healthFacilityDistricts" :key="district.id" :value="district.id">{{
        district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Observation Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'observation_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Observation</p>
              <span class="main-count">
                <p class="count">{{ serviceProviderCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="serviceProviderProvince" @change="fetchDistrictsAndCount('serviceProvider', 4)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="serviceProviderDistrict" @change="fetchCount('serviceProvider', 4)">
                <option disabled value="">Select District</option>
                <option v-for="district in serviceProviderDistricts" :key="district.id" :value="district.id">{{
        district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col tabs">

        <div class="content">
          <ul class="nav-tabs">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'Main' }" @click.prevent="activeTab = 'Main'"
                href="#">Main</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'Customize' }"
                @click.prevent="activeTab = 'Customize'" @click="toggleAccordion()" href="#">Customize</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'HealthCenterAnalysis' }"
                @click.prevent="activeTab = 'HealthCenterAnalysis'" @click="toggleAccordion()" href="#">Health Center
                Analysis</a>
            </li>
            <li v-if="dashboards && dashboards.length" v-for="dashboard in dashboards" :key="dashboard.id"
              class="nav-item dashboard-item">
              <a class="nav-link" :class="{ active: activeTab === 'Dashboard-' + dashboard.id }"
                @click.prevent="loadDashboard(dashboard.id)" href="#">
                {{ dashboard.name }}
              </a>
              <svg style="cursor: pointer;" @click="confirmDelete(dashboard.id)" width="38" height="38"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM8.96965 8.96967C9.26254 8.67678 9.73742 8.67678 10.0303 8.96967L12 10.9394L13.9696 8.96969C14.2625 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0303L13.0606 12L15.0303 13.9697C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73744 15.3232 9.26256 15.3232 8.96967 15.0303C8.67678 14.7374 8.67678 14.2626 8.96967 13.9697L10.9393 12L8.96965 10.0303C8.67676 9.73744 8.67676 9.26256 8.96965 8.96967Z"
                  fill="#1C3454" fill-opacity="0.26" />
              </svg>

            </li>
          </ul>
          <div class="tab-content mt-3">
            <div v-if="activeTab === 'Main'">
              <div class="row">
                <div class="col1 usersCol">
                  <userAnalyticsVue />
                </div>
                <div class="col conditonAnalytics">
                  <conditionAnalyticsVue />
                </div>
              </div>
              <div class="row">
                <div class="col WeeklyDiagnosis">
                  <WeeklyDiagnosisVue />
                </div>
              </div>
              <div class="row">
                <div class="col questionCol">
                  <questionAnalyticsVue />
                </div>
              </div>
              <div class="row">
                <div class="col QuestionAnalysis">
                  <QuestionAnalysis />
                </div>
              </div>

            </div>
            <div v-if="activeTab === 'Customize'">
              <div class="horizontal-tabs">
                <ul class="sub-tabs">
                  <a class="nav-link" :class="{ active: activeCustomizeTab === 'Locations' }"
                    @click.prevent="activeCustomizeTab = 'Locations'" @click="toggleAccordion()" href="#">
                    <li class="sub-link">


                      <svg class="locationIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.6667 2C8.79666 2 5.66666 5.13 5.66666 9C5.66666 14.25 12.6667 22 12.6667 22C12.6667 22 19.6667 14.25 19.6667 9C19.6667 5.13 16.5367 2 12.6667 2ZM7.66666 9C7.66666 6.24 9.90666 4 12.6667 4C15.4267 4 17.6667 6.24 17.6667 9C17.6667 11.88 14.7867 16.19 12.6667 18.88C10.5867 16.21 7.66666 11.85 7.66666 9ZM10.1667 9C10.1667 7.61929 11.2859 6.5 12.6667 6.5C13.5598 6.5 14.3851 6.9765 14.8317 7.75C15.2783 8.5235 15.2783 9.4765 14.8317 10.25C14.3851 11.0235 13.5598 11.5 12.6667 11.5C11.2859 11.5 10.1667 10.3807 10.1667 9Z"
                          fill="#1C274C" fill-opacity="0.3" />
                      </svg>

                      <span>Locations</span>
                    </li>
                  </a>

                  <a class="nav-link" :class="{ active: activeCustomizeTab === 'Tools' }"
                    @click.prevent="activeCustomizeTab = 'Tools'" href="#">
                    <li class="sub-link">

                      <svg class="toolsIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.34308 3.80211C10.3718 2.6007 11.3862 2 12.5 2C13.6138 2 14.6282 2.6007 16.6569 3.80211L17.3431 4.20846C19.3718 5.40987 20.3862 6.01057 20.9431 7C21.5 7.98943 21.5 9.19084 21.5 11.5937V12.4063C21.5 14.8092 21.5 16.0106 20.9431 17C20.3862 17.9894 19.3718 18.5901 17.3431 19.7915L16.6569 20.1979C14.6282 21.3993 13.6138 22 12.5 22C11.3862 22 10.3718 21.3993 8.34308 20.1979L7.65692 19.7915C5.6282 18.5901 4.61384 17.9894 4.05692 17C3.5 16.0106 3.5 14.8092 3.5 12.4063V11.5937C3.5 9.19084 3.5 7.98943 4.05692 7C4.61384 6.01057 5.6282 5.40987 7.65692 4.20846L8.34308 3.80211Z"
                          stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" />
                        <circle cx="12.5" cy="12" r="3" stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" />
                      </svg>
                      <span>
                        Tools
                      </span>
                    </li>
                  </a>

                  <a class="nav-link" :class="{ active: activeCustomizeTab === 'Urgency' }"
                    @click.prevent="activeCustomizeTab = 'Urgency'" href="#">
                    <li class="sub-link">

                      <svg class="urgencyIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.8334 12L9.87568 14.9289C6.95319 17.823 5.49194 19.27 5.90103 20.5185C5.93619 20.6258 5.97986 20.7302 6.03162 20.8307C6.63383 22 8.70035 22 12.8334 22C16.9664 22 19.0329 22 19.6351 20.8307C19.6869 20.7302 19.7306 20.6258 19.7657 20.5185C20.1748 19.27 18.7136 17.823 15.7911 14.9289L12.8334 12ZM12.8334 12L15.7911 9.07107C18.7136 6.177 20.1748 4.72997 19.7657 3.48149C19.7306 3.37417 19.6869 3.26977 19.6351 3.16926C19.0329 2 16.9664 2 12.8334 2C8.70035 2 6.63383 2 6.03162 3.16926C5.97986 3.26977 5.93619 3.37417 5.90103 3.48149C5.49194 4.72997 6.95319 6.177 9.87568 9.07107L12.8334 12Z"
                          stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" />
                      </svg>

                      <span>Urgency</span>
                    </li>
                  </a>

                  <a class="nav-link" :class="{ active: activeCustomizeTab === 'Condition' }"
                    @click.prevent="activeCustomizeTab = 'Condition'" href="#">
                    <li class="sub-link">

                      <svg class="conditionIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.1666" cy="12" r="6.25" stroke="#1C274C" stroke-opacity="0.3" />
                        <path d="M12.1666 5L12.1666 5.5" stroke="#1C274C" stroke-opacity="0.3" stroke-linecap="round" />
                        <path d="M12.1666 17.5V19.5" stroke="#1C274C" stroke-opacity="0.3" stroke-linecap="round" />
                        <path
                          d="M7.31307 7.85355C7.50833 8.04882 7.82492 8.04882 8.02018 7.85355C8.21544 7.65829 8.21544 7.34171 8.02018 7.14645L7.31307 7.85355ZM6.81307 7.35355L7.31307 7.85355L8.02018 7.14645L7.52018 6.64645L6.81307 7.35355Z"
                          fill="#1C274C" fill-opacity="0.3" />
                        <path
                          d="M15.5631 7.89645C15.3678 8.09171 15.3678 8.40829 15.5631 8.60355C15.7583 8.79882 16.0749 8.79882 16.2702 8.60355L15.5631 7.89645ZM16.8131 6.64645L15.5631 7.89645L16.2702 8.60355L17.5202 7.35355L16.8131 6.64645Z"
                          fill="#1C274C" fill-opacity="0.3" />
                        <path
                          d="M17.0202 16.1464C16.8249 15.9512 16.5083 15.9512 16.3131 16.1464C16.1178 16.3417 16.1178 16.6583 16.3131 16.8536L17.0202 16.1464ZM17.5202 16.6464L17.0202 16.1464L16.3131 16.8536L16.8131 17.3536L17.5202 16.6464Z"
                          fill="#1C274C" fill-opacity="0.3" />
                        <path d="M4.66663 12L5.66663 12" stroke="#1C274C" stroke-opacity="0.3" stroke-linecap="round" />
                        <path d="M17.6666 12L19.6666 12" stroke="#1C274C" stroke-opacity="0.3" stroke-linecap="round" />
                        <path
                          d="M8.77018 16.1036C8.96544 15.9083 8.96544 15.5917 8.77018 15.3964C8.57492 15.2012 8.25833 15.2012 8.06307 15.3964L8.77018 16.1036ZM7.52018 17.3536L8.77018 16.1036L8.06307 15.3964L6.81307 16.6464L7.52018 17.3536Z"
                          fill="#1C274C" fill-opacity="0.3" />
                        <path
                          d="M12.6666 9C12.6666 9.55228 12.2189 10 11.6666 10C11.1143 10 10.6666 9.55228 10.6666 9C10.6666 8.44772 11.1143 8 11.6666 8C12.2189 8 12.6666 8.44772 12.6666 9Z"
                          stroke="#1C274C" stroke-opacity="0.3" />
                        <path
                          d="M10.6666 13C10.6666 13.5523 10.2189 14 9.66663 14C9.11434 14 8.66663 13.5523 8.66663 13C8.66663 12.4477 9.11434 12 9.66663 12C10.2189 12 10.6666 12.4477 10.6666 13Z"
                          stroke="#1C274C" stroke-opacity="0.3" />
                        <path
                          d="M16.1666 14C16.1666 14.5523 15.7189 15 15.1666 15C14.6143 15 14.1666 14.5523 14.1666 14C14.1666 13.4477 14.6143 13 15.1666 13C15.7189 13 16.1666 13.4477 16.1666 14Z"
                          stroke="#1C274C" stroke-opacity="0.3" />
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11.1666 4.5)" stroke="#1C274C"
                          stroke-opacity="0.3" />
                        <path
                          d="M13.1666 20.5C13.1666 19.9477 12.7189 19.5 12.1666 19.5C11.6143 19.5 11.1666 19.9477 11.1666 20.5C11.1666 21.0523 11.6143 21.5 12.1666 21.5C12.7189 21.5 13.1666 21.0523 13.1666 20.5Z"
                          stroke="#1C274C" stroke-opacity="0.3" />
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5.16663 7)" stroke="#1C274C"
                          stroke-opacity="0.3" />
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 17.1666 7)" stroke="#1C274C"
                          stroke-opacity="0.3" />
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 17.1666 19)" stroke="#1C274C"
                          stroke-opacity="0.3" />
                        <path
                          d="M4.66663 12C4.66663 11.4477 4.21891 11 3.66663 11C3.11434 11 2.66663 11.4477 2.66663 12C2.66663 12.5523 3.11434 13 3.66663 13C4.21891 13 4.66663 12.5523 4.66663 12Z"
                          stroke="#1C274C" stroke-opacity="0.3" />
                        <path
                          d="M21.6666 12C21.6666 11.4477 21.2189 11 20.6666 11C20.1143 11 19.6666 11.4477 19.6666 12C19.6666 12.5523 20.1143 13 20.6666 13C21.2189 13 21.6666 12.5523 21.6666 12Z"
                          stroke="#1C274C" stroke-opacity="0.3" />
                        <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5.16663 19)" stroke="#1C274C"
                          stroke-opacity="0.3" />
                      </svg>
                      <span>Condition</span>
                    </li>
                  </a>
                  <a class="nav-link" :class="{ active: activeCustomizeTab === 'Question' }"
                    @click.prevent="activeCustomizeTab = 'Question'" href="#">
                    <li class="sub-link">

                      <svg class="questionsIcon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.99988 12C1.99988 7.28595 1.99988 4.92893 3.46434 3.46447C4.92881 2 7.28583 2 11.9999 2C16.7139 2 19.0709 2 20.5354 3.46447C21.9999 4.92893 21.9999 7.28595 21.9999 12C21.9999 16.714 21.9999 19.0711 20.5354 20.5355C19.0709 22 16.7139 22 11.9999 22C7.28583 22 4.92881 22 3.46434 20.5355C1.99988 19.0711 1.99988 16.714 1.99988 12Z"
                          stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" />
                        <path
                          d="M10.1249 8.875C10.1249 7.83947 10.9643 7 11.9999 7C13.0354 7 13.8749 7.83947 13.8749 8.875C13.8749 9.56245 13.5049 10.1635 12.9532 10.4899C12.4779 10.7711 11.9999 11.1977 11.9999 11.75V13"
                          stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" stroke-linecap="round" />
                        <circle cx="11.9999" cy="16" r="1" fill="#1C274C" fill-opacity="0.3" />
                      </svg>

                      <span>Question</span>
                    </li>
                  </a>
                  <a class="nav-link" :class="{ active: activeCustomizeTab === 'Status' }"
                    @click.prevent="activeCustomizeTab = 'Status'" href="#">
                    <li class="sub-link">
                      <svg class="statusIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M15.8265 6.93496C16.1385 7.20737 16.1707 7.68116 15.8983 7.99319L8.04111 16.9932C7.89868 17.1563 7.69269 17.2499 7.47612 17.2499C7.25955 17.2499 7.05356 17.1563 6.91113 16.9932L3.76827 13.3932C3.49586 13.0812 3.52799 12.6074 3.84002 12.335C4.15205 12.0626 4.62584 12.0947 4.89825 12.4067L7.47612 15.3595L14.7683 7.00671C15.0407 6.69467 15.5145 6.66255 15.8265 6.93496Z"
                          fill="#1C274C" fill-opacity="0.3" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M20.8507 7.0194C21.1506 7.30507 21.1622 7.7798 20.8765 8.07975L12.3049 17.0797C12.1534 17.2388 11.9398 17.3235 11.7205 17.3114C11.5011 17.2993 11.2981 17.1916 11.1651 17.0169L10.7368 16.4544C10.4858 16.1248 10.5495 15.6542 10.8791 15.4033C11.1622 15.1877 11.5494 15.2044 11.812 15.4223L19.7904 7.04525C20.076 6.7453 20.5508 6.73373 20.8507 7.0194Z"
                          fill="#1C274C" fill-opacity="0.3" />
                      </svg>

                      <span>Status</span>
                    </li>
                  </a>

                </ul>
              </div>
              <div class="row">
                <div :class="['col-3', 'sidebar', { 'sidebar-wide': activeCustomizeTab === 'Question' }]">
                  <div class="content">
                    <div v-if="activeCustomizeTab === 'Locations'">
                      <div class="container">
                        <div v-for="province in provinces" :key="province.id" class="province">
                          <button class="accordion" @click.prevent="fetchCustomDistricts(province.id)">
                            {{ province.name }}
                            <!-- <span class="icon">
                              <svg v-if="expandedProvince !== province.id" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <svg v-else width="24" height="24" viewBox="0 0 24 24">
                                <path d="M7.20001 14.4L12 9.60002L16.8 14.4" stroke="#192434" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </span> -->
                          </button>
                          <div v-show="expandedProvince === province.id" class="panel">
                            <ul>
                              <li v-if="loadingDistricts" class="loading">Loading...</li>
                              <li v-else v-for="district in districts" :key="district.id">
                                <input type="checkbox" :id="'district-' + district.id" :value="district.id"
                                  v-model="selectedDistricts" />
                                <label :for="'district-' + district.id">{{ district.name }}</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="activeCustomizeTab === 'Tools'" class="customize-tab">
                      <h4>Tools</h4>
                      <ul class="styled-list">
                        <li v-for="tool in tools" :key="tool.id" class="styled-list-item">
                          <input type="checkbox" :id="'tool-' + tool.id" :value="tool.id" v-model="selectedTools" />
                          <label :for="'tool-' + tool.id">{{ tool.name }}</label>
                        </li>
                      </ul>
                    </div>
                    <div v-if="activeCustomizeTab === 'Condition'" class="customize-tab">
                      <h4>Conditions</h4>
                      <ul class="styled-list">
                        <li v-for="condition in conditions" :key="condition.id" class="styled-list-item">
                          <input type="checkbox" :id="'condition-' + condition.id" :value="condition.id"
                            v-model="selectedConditions" />
                          <label :for="'condition-' + condition.id">{{ condition.name }}</label>
                        </li>
                      </ul>
                    </div>
                    <div v-if="activeCustomizeTab === 'Question'" class="customize-tab customize-tab-question">
                      <ul class="styled-list">
                        <li v-for="question in questions" :key="question.id"
                          class="styled-list-item styled-list-item-question">
                          <input type="checkbox" :id="'question-' + question.id" :value="question.id"
                            v-model="selectedQuestions" />
                          <label :for="'question-' + question.id">
                            {{ truncateText(question.question, 50) }}
                            <span v-if="question.question.length > 50" @click="showFullQuestion(question)">
                              ... (View)
                            </span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div v-if="activeCustomizeTab === 'Urgency'" class="customize-tab">
                      <h4>Urgencies</h4>
                      <ul class="styled-list">
                        <li class="styled-list-item">
                          <input type="checkbox" id="urgency-high" value="high" v-model="selectedUrgency" />
                          <label for="urgency-high">High</label>
                        </li>
                        <li class="styled-list-item">
                          <input type="checkbox" id="urgency-medium" value="medium" v-model="selectedUrgency" />
                          <label for="urgency-medium">Medium</label>
                        </li>
                        <li class="styled-list-item">
                          <input type="checkbox" id="urgency-low" value="low" v-model="selectedUrgency" />
                          <label for="urgency-low">Low</label>
                        </li>
                      </ul>
                    </div>
                    <div v-if="activeCustomizeTab === 'Status'" class="customize-tab">
                      <h4>Status</h4>
                      <ul class="styled-list">
                        <li class="styled-list-item">
                          <input type="checkbox" id="status-progress" value="progress" v-model="selectedStatus" />
                          <label for="status-progress">In Progress</label>
                        </li>
                        <li class="styled-list-item">
                          <input type="checkbox" id="status-resolved" value="resolved" v-model="selectedStatus" />
                          <label for="status-resolved">Resolved</label>
                        </li>
                      </ul>
                    </div>
                    <p v-if="activeCustomizeTab === 'Comments'">Comments details...</p>
                  </div>
                </div>
                <div class="col" style="margin-top: 20px;">
                  <div class="card">
                    <div class="content">
                      <ul class="selected-items">
                        <li v-for="district in selectedDistrictsDetails" :key="district.id" class="selected-item">
                          {{ district.name }}
                          <button @click="removeDistrict(district.id)">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                fill="#44474F" />
                            </svg>
                          </button>
                        </li>
                        <li v-for="tool in selectedToolsDetails" :key="tool.id" class="selected-item">
                          {{ tool.name }}
                          <button @click="removeTool(tool.id)"><svg width="18" height="18" viewBox="0 0 18 18"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                fill="#44474F" />
                            </svg></button>
                        </li>
                        <li v-for="condition in selectedConditionsDetails" :key="condition.id" class="selected-item">
                          {{ condition.name }}
                          <button @click="removeCondition(condition.id)"><svg width="18" height="18" viewBox="0 0 18 18"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                fill="#44474F" />
                            </svg></button>
                        </li>
                        <li v-for="question in selectedQuestionsDetails" :key="question.id" class="selected-item">
                          {{ question.question }}
                          <button @click="removeQuestion(question.id)"><svg width="18" height="18" viewBox="0 0 18 18"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                fill="#44474F" />
                            </svg></button>
                        </li>
                        <li v-for="urgency in selectedUrgency" :key="urgency" class="selected-item">
                          {{ urgency }}
                          <button @click="removeUrgency(urgency)"><svg width="18" height="18" viewBox="0 0 18 18"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                fill="#44474F" />
                            </svg></button>
                        </li>
                        <li v-for="status in selectedStatus" :key="status" class="selected-item">
                          {{ status }}
                          <button @click="removeStatus(status)"><svg width="18" height="18" viewBox="0 0 18 18"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                fill="#44474F" />
                            </svg></button>
                        </li>
                      </ul>
                      <div class="button-container">
                        <div class="left">
                          <button @click="resetSelections" class="btn-cancel">Cancel</button>
                          <button @click="previewCharts" class="btn-preview">Preview</button>
                          <!-- <button @click="createCharts" class="btn-create">Create</button> -->
                        </div>
                      </div>

                    </div>
                  </div>

                  <div v-if="showChartModal" class="chart-preview-modal">
                    <div class="chart-preview-modal-content">
                      <span class="chart-preview-close" @click="closeChartModal">&times;</span>
                      <h3>Chart Preview</h3>
                      <div class="chart-preview-container">
                        <div v-for="(chart, index) in chartsData.questions" :key="index"
                          class="chart-preview-canvas-container">
                          <canvas :id="'chart-' + chart.id"></canvas>
                        </div>
                        <div class="chart-preview-canvas-container">
                          <canvas id="chart-urgencies"></canvas>
                        </div>
                        <div class="chart-preview-canvas-container">
                          <canvas id="chart-statuses"></canvas>
                        </div>
                      </div>
                      <div class="chart-preview-modal-actions">
                        <button @click="closeChartModal" class="btn-cancel">Close</button>
                        <div class="right">
                          <button @click="saveDashboard" class="btn-save">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9.75 3H8.25V8.25002L3 8.24999V9.74999L8.25 9.75002V15H9.75V9.75002H15V8.25002L9.75 8.24999V3Z"
                                fill="white" />
                            </svg>
                            <span>Save Dashboard</span></button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div v-if="activeTab === 'HealthCenterAnalysis'">
              <HealthCenterAnalysis />
            </div>
            <div v-if="activeTab.startsWith('Dashboard-')">
              <div class="row">
                <div class="col" style="margin-top: 20px;">
                  <!-- <button @click="saveChartSettings">Save Changes</button> -->
                  <div class="save-change-button">
                    <button @click="saveChartSettings"><svg data-v-3e46a7ef="" width="18" height="18"
                        viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path data-v-3e46a7ef="" fill-rule="evenodd" clip-rule="evenodd"
                          d="M1.55201 3.75629C1.5 4.03196 1.5 4.36291 1.5 5.02481V10.3125C1.5 13.1409 1.5 14.5551 2.37868 15.4338C3.25736 16.3125 4.67157 16.3125 7.5 16.3125H10.5C13.3284 16.3125 14.7426 16.3125 15.6213 15.4338C16.5 14.5551 16.5 13.1409 16.5 10.3125V8.66093C16.5 6.68665 16.5 5.69951 15.9229 5.05787C15.8698 4.99885 15.8136 4.94268 15.7546 4.8896C15.113 4.3125 14.1258 4.3125 12.1516 4.3125H11.8713C11.0061 4.3125 10.5734 4.3125 10.1703 4.19758C9.94879 4.13445 9.73533 4.04603 9.53409 3.93407C9.16775 3.73025 8.86183 3.42433 8.25 2.8125L7.83731 2.39981C7.63224 2.19474 7.52971 2.09221 7.42196 2.00289C6.95739 1.61778 6.38749 1.38171 5.78668 1.32553C5.64732 1.3125 5.50232 1.3125 5.21231 1.3125C4.55041 1.3125 4.21946 1.3125 3.94379 1.36451C2.73023 1.59348 1.78098 2.54273 1.55201 3.75629ZM9 8.25C9.31066 8.25 9.5625 8.50184 9.5625 8.8125V9.75H10.5C10.8107 9.75 11.0625 10.0018 11.0625 10.3125C11.0625 10.6232 10.8107 10.875 10.5 10.875H9.5625V11.8125C9.5625 12.1232 9.31066 12.375 9 12.375C8.68934 12.375 8.4375 12.1232 8.4375 11.8125V10.875H7.5C7.18934 10.875 6.9375 10.6232 6.9375 10.3125C6.9375 10.0018 7.18934 9.75 7.5 9.75H8.4375V8.8125C8.4375 8.50184 8.68934 8.25 9 8.25Z"
                          fill="white"></path>
                      </svg><span data-v-3e46a7ef="">Save Changes</span></button>
                  </div>
                  <div class="card special-card">
                    <div class="content">
                      <div v-if="selectedType === 'graph'" class="chart-container">
                        <vue-draggable-resizable v-for="(chart, index) in chartsData.questions" :key="index"
                          class="canvas-container" :w="getChartSize(chart.id, 'width', 600)"
                          :h="getChartSize(chart.id, 'height', 300)" @resize="(size) => onResizing(chart.id, size)"
                          @resize-stop="(size) => onResizeStop(chart.id, size)">
                          <div class="card">
                            <div class="chart-header">
                              <button @click="toggleDropdown(index)" class="dropdown-btn">⋮</button>
                              <div v-if="dropdowns[index]" class="dropdown-content">
                                <a href="#" @click.prevent="updateChartType(chart.id, index, 'line')">Line</a>
                                <a href="#" @click.prevent="updateChartType(chart.id, index, 'bar')">Bar</a>
                                <a href="#" @click.prevent="updateChartType(chart.id, index, 'radar')">Radar</a>
                                <a href="#" @click.prevent="updateChartType(chart.id, index, 'doughnut')">Doughnut</a>
                                <a href="#" @click.prevent="updateChartType(chart.id, index, 'pie')">Pie</a>
                                <a href="#" @click.prevent="updateChartType(chart.id, index, 'polarArea')">Polar
                                  Area</a>
                              </div>
                            </div>
                            <div class="chart-wrapper">
                              <canvas :id="'chart-' + chart.id"></canvas>
                            </div>
                          </div>
                        </vue-draggable-resizable>

                        <vue-draggable-resizable class="canvas-container" :w="getChartSize('urgencies', 'width', 600)"
                          :h="getChartSize('urgencies', 'height', 300)"
                          @resize="(size) => onResizing('urgencies', size)"
                          @resize-stop="(size) => onResizeStop('urgencies', size)">
                          <div class="card">
                            <div class="chart-header">
                              <button @click="toggleDropdown('urgencies')" class="dropdown-btn">⋮</button>
                              <div v-if="dropdowns['urgencies']" class="dropdown-content">
                                <a href="#" @click.prevent="updateChartType('urgencies', 'line')">Line</a>
                                <a href="#" @click.prevent="updateChartType('urgencies', 'bar')">Bar</a>
                                <a href="#" @click.prevent="updateChartType('urgencies', 'radar')">Radar</a>
                                <a href="#" @click.prevent="updateChartType('urgencies', 'doughnut')">Doughnut</a>
                                <a href="#" @click.prevent="updateChartType('urgencies', 'pie')">Pie</a>
                                <a href="#" @click.prevent="updateChartType('urgencies', 'polarArea')">Polar Area</a>
                              </div>
                            </div>
                            <div class="chart-wrapper">
                              <canvas id="chart-urgencies"></canvas>
                            </div>
                          </div>
                        </vue-draggable-resizable>

                        <vue-draggable-resizable class="canvas-container" :w="getChartSize('statuses', 'width', 600)"
                          :h="getChartSize('statuses', 'height', 300)" @resize="(size) => onResizing('statuses', size)"
                          @resize-stop="(size) => onResizeStop('statuses', size)">
                          <div class="card">
                            <div class="chart-header">
                              <button @click="toggleDropdown('statuses')" class="dropdown-btn">⋮</button>
                              <div v-if="dropdowns['statuses']" class="dropdown-content">
                                <a href="#" @click.prevent="updateChartType('statuses', 'line')">Line</a>
                                <a href="#" @click.prevent="updateChartType('statuses', 'bar')">Bar</a>
                                <a href="#" @click.prevent="updateChartType('statuses', 'radar')">Radar</a>
                                <a href="#" @click.prevent="updateChartType('statuses', 'doughnut')">Doughnut</a>
                                <a href="#" @click.prevent="updateChartType('statuses', 'pie')">Pie</a>
                                <a href="#" @click.prevent="updateChartType('statuses', 'polarArea')">Polar Area</a>
                              </div>
                            </div>
                            <div class="chart-wrapper">
                              <canvas id="chart-statuses"></canvas>
                            </div>
                          </div>
                        </vue-draggable-resizable>

                      </div>
                      <div v-else-if="selectedType === 'hc_graph'" class="chart-container">
                        <vue-draggable-resizable v-for="(chart, index) in hcGraphData" :key="index"
                          class="canvas-container" :w="getChartSize(index, 'width', 600)"
                          :h="getChartSize(index, 'height', 300)" @resize="(size) => onResizing(index, size)"
                          @resize-stop="(size) => onResizeStop(index, size)">
                          <div class="card">
                            <!-- <div class="chart-header">
                              <button @click="toggleDropdown(index)" class="dropdown-btn">⋮</button>
                              <div v-if="dropdowns[index]" class="dropdown-content">
                                <a href="#" @click.prevent="updateChartType(index, index, 'line')">Line</a>
                                <a href="#" @click.prevent="updateChartType(index, index, 'bar')">Bar</a>
                                <a href="#" @click.prevent="updateChartType(index, index, 'radar')">Radar</a>
                                <a href="#" @click.prevent="updateChartType(index, index, 'doughnut')">Doughnut</a>
                                <a href="#" @click.prevent="updateChartType(index, index, 'pie')">Pie</a>
                                <a href="#" @click.prevent="updateChartType(index, index, 'polarArea')">Polar
                                  Area</a>
                              </div>
                            </div> -->
                            <div class="chart-wrapper">
                              <canvas :id="'chart-' + index"></canvas>
                            </div>
                          </div>
                        </vue-draggable-resizable>

                      </div>

                      <div v-else>
                        <div class="table-container">
                          <button @click="downloadExcel" class="download-button"><i class="fa fa-download"></i> Download
                            Excel</button>
                          <table>
                            <thead>
                              <tr>
                                <th>Condition</th>
                                <th>Question</th>
                                <th>Answers</th>
                                <th>District</th>
                                <th>Area</th>
                                <th v-for="center in healthCenterNames" :key="center">{{ center }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(question, qIndex) in tableData" :key="qIndex">
                                <template v-for="(response, rIndex) in question.responses" :key="rIndex">
                                  <tr>
                                    <td v-if="rIndex === 0" :rowspan="getTotalRowspan(question.responses)">
                                      {{ question.condition }}</td>
                                    <td v-if="rIndex === 0" :rowspan="getTotalRowspan(question.responses)">
                                      {{ question.question }}</td>
                                    <td :rowspan="response.health_centers.length">{{ response.response }}</td>
                                    <td>{{ response.health_centers[0].district }}</td>
                                    <td>{{ response.health_centers[0].location }}</td>
                                    <td v-for="center in healthCenterNames" :key="center">
                                      {{ getHealthCenterCount(response.health_centers, center, center) }}
                                    </td>
                                  </tr>
                                  <template v-for="(center, cIndex) in response.health_centers" :key="cIndex">
                                    <tr v-if="cIndex !== 0">
                                      <td>{{ center.district }}</td>
                                      <td>{{ center.location }}</td>
                                      <td v-for="hc in healthCenterNames" :key="hc">
                                        {{ getHealthCenterCount([center], hc, hc) }}
                                      </td>
                                    </tr>
                                  </template>
                                </template>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <!-- <div v-if="showModal" class="modal-overlay">
          <div class="modal">
            <div class="modal-header">
              <h3>Full Question</h3>
              <button class="close-button" @click="closeModal">x</button>
            </div>
            <div class="modal-content">
              <p>{{ fullQuestion }}</p>
            </div>
          </div>
        </div> -->
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <h3>Full Question</h3>
            <p>{{ fullQuestion }}</p>
            <div class="modal-actions">
              <button class="cancel" @click="closeModal">Close</button>
            </div>
          </div>
        </div>
        <!-- Naming Dashboard model -->
        <!-- <div v-if="isNameModalOpen" class="modal-overlay">
            <div class="modal">
              <div class="modal-content">
                <span class="close" @click="closeNameModal">&times;</span>
                <h3>Enter Dashboard Name</h3>
                <div>
                  <label for="dashboardName">Name:</label>
                  <input type="text" v-model="dashboardName" id="dashboardName" />
                </div>
                <div class="modal-actions">
                  <button class="save" @click="saveDashboardWithName">Save</button>
                  <button class="cancel" @click="closeNameModal">Cancel</button>
                </div>
              </div>
            </div>
          </div> -->

      </div>
    </div>
    <div v-if="isNameModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeNameModal">&times;</span>
        <h3>Name Your Dashboard</h3>
        <input type="text" v-model="dashboardName" placeholder="Enter dashboard name" required />
        <div class="modal-actions">
          <button class="save" @click="saveDashboardWithName">Save</button>
          <button class="cancel" @click="closeNameModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { mapGetters } from "vuex";
import axiosRetry from "axios-retry";
import conditionAnalyticsVue from "./charts/conditionAnalytics.vue";
import userAnalyticsVue from "./charts/userAnalytics.vue";
import WeeklyDiagnosisVue from "./charts/WeeklyDiagnosis.vue";
import questionAnalyticsVue from "./charts/questionAnalytics.vue";
import QuestionAnalysis from "./table/QuestionAnalysis.vue";
import HealthCenterAnalysis from "./customize/HealthCenterAnalysis.vue";
import Chart from "chart.js/auto";
import VueDraggableResizable from "vue3-draggable-resizable";
import * as XLSX from "xlsx";
import { useToast } from "vue-toastification";

const Toast = useToast();
// import "vue3-draggable-resizable/dist/Vue3DraggableResizer.css";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 2000; // Time between retries, increasing exponentially
  },
  retryCondition: (error) => {
    return error.response && error.response.status === 429; // Retry only if the status code is 429
  },
});

export default {
  components: {
    VueDraggableResizable,
    conditionAnalyticsVue,
    userAnalyticsVue,
    WeeklyDiagnosisVue,
    questionAnalyticsVue,
    QuestionAnalysis,
    HealthCenterAnalysis,
  },
  data() {
    return {
      expandedProvince: null,
      loadingDistricts: false,
      activeTab: "Main",
      activeCustomizeTab: "Locations",
      username: localStorage.getItem("fullname"),
      userId: 1,
      dashboards: [],
      patientToolCount: 0,
      communityBasedCount: 0,
      healthFacilityCount: 0,
      serviceProviderCount: 0,
      provinces: [],
      selectedProvince: null,
      selectedProvinceName: "",
      districts: [],
      selectedDistricts: [],
      districtsByProvince: {},
      tools: [],
      selectedTools: [],
      conditions: [],
      selectedConditions: [],
      questions: [],
      selectedQuestions: [],
      fullQuestion: "",
      showModal: false,
      selectedUrgency: [],
      selectedStatus: [],
      chartsData: {
        questions: [],
        urgencies: {},
        statuses: {},
      },
      isNameModalOpen: false,
      dashboardName: "",
      patientToolProvince: "",
      patientToolDistrict: "",
      patientToolDistricts: [],
      communityBasedProvince: "",
      communityBasedDistrict: "",
      communityBasedDistricts: [],
      healthFacilityProvince: "",
      healthFacilityDistrict: "",
      healthFacilityDistricts: [],
      serviceProviderProvince: "",
      serviceProviderDistrict: "",
      serviceProviderDistricts: [],
      chartTypes: {},
      chartInstances: {},
      dropdowns: {},
      chartSizes: {},
      tableData: [],
      hcGraphData: [],
      healthCenterNames: [],
      showChartModal: false,
    };
  },
  mounted() {
    this.fetchCounts();
    this.fetchProvinces();
    this.fetchTools();
    this.fetchConditions();
    this.fetchDashboards();
    this.toggleAccordion();
    this.healthCenterNames = this.extractHealthCenterNames(this.tableData);
  },
  methods: {
    toggleDropdown(index) {
      if (this.dropdowns[index] === undefined) {
        this.$nextTick(() => {
          this.dropdowns[index] = true;
        });
      } else {
        this.$nextTick(() => {
          this.dropdowns[index] = !this.dropdowns[index];
        });
      }
    },
    updateChartType(chartId, index, type) {
      this.chartTypes = {
        ...this.chartTypes,
        [chartId]: type,
      };
      this.dropdowns[index] = false; // Close the dropdown after selection
      this.renderCharts();
      // this.renderHealthCenterCharts();
    },
    toggleAccordion() {
      setTimeout(() => {
        var acc = document.getElementsByClassName("accordion");
        var i;
        for (i = 0; i < acc.length; i++) {
          acc[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
            } else {
              panel.style.maxHeight = panel.scrollHeight + "px";
            }
          });
        }
      }, 1000);
    },
    fetchCounts() {
      this.fetchCount("patientTool", 1);
      this.fetchCount("communityBased", 3);
      this.fetchCount("healthFacility", 2);
      this.fetchCount("serviceProvider", 4);
    },
    fetchDistrictsAndCount(tool, toolId) {
      this.fetchDistricts(tool);
      this.fetchCount(tool, toolId);
    },
    fetchProvinces() {
      axios
        .get("/province")
        .then((response) => {
          this.provinces = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching provinces:", error);
        });
    },
    fetchDistricts(tool) {
      let selectedProvince = "";
      if (tool === "patientTool") {
        selectedProvince = this.patientToolProvince;
      } else if (tool === "communityBased") {
        selectedProvince = this.communityBasedProvince;
      } else if (tool === "healthFacility") {
        selectedProvince = this.healthFacilityProvince;
      } else if (tool === "serviceProvider") {
        selectedProvince = this.serviceProviderProvince;
      }
      if (selectedProvince) {
        axios
          .get(`/districts/${selectedProvince}`)
          .then((response) => {
            if (tool === "patientTool") {
              this.patientToolDistricts = response.data.data;
              this.patientToolDistrict = "";
            } else if (tool === "communityBased") {
              this.communityBasedDistricts = response.data.data;
              this.communityBasedDistrict = "";
            } else if (tool === "healthFacility") {
              this.healthFacilityDistricts = response.data.data;
              this.healthFacilityDistrict = "";
            } else if (tool === "serviceProvider") {
              this.serviceProviderDistricts = response.data.data;
              this.serviceProviderDistrict = "";
            }
          })
          .catch((error) => {
            console.error("Error fetching districts:", error);
          });
      }
    },
    toggleProvince(provinceId) {
      if (this.expandedProvince === provinceId) {
        this.expandedProvince = null;
      } else {
        this.expandedProvince = provinceId;
        if (!this.districtsByProvince[provinceId]) {
          this.fetchCustomDistricts(provinceId);
        }
      }
    },
    fetchCustomDistricts(provinceId) {
      if (this.expandedProvince === provinceId) {
        this.expandedProvince = null;
      } else {
        this.expandedProvince = provinceId;
        this.loadingDistricts = true;
        axios
          .get(`/districts/${provinceId}`)
          .then((response) => {
            this.districts = response.data.data;
            this.loadingDistricts = false;
          })
          .catch((error) => {
            console.error("Error fetching districts:", error);
            this.loadingDistricts = false;
          });
      }
    },
    getDistricts(provinceId) {
      return this.districtsByProvince[provinceId] || [];
    },
    panelHeight(provinceId) {
      const districts = this.getDistricts(provinceId);
      return districts.length * 40 + "px";
    },
    fetchConditions() {
      axios
        .get("/conditions")
        .then((response) => {
          if (response.data.status) {
            this.conditions = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching conditions:", error);
        });
    },
    fetchQuestions() {
      const requestData = {
        tool_id: this.selectedTools,
        condition_id: this.selectedConditions,
        district_id: this.selectedDistricts,
      };
      axios
        .post("/customized-questions", requestData)
        .then((response) => {
          if (response.data.status) {
            this.questions = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching questions:", error);
        });
    },
    fetchTools() {
      axios
        .get("/tools")
        .then((response) => {
          if (response.data.status) {
            this.tools = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching tools:", error);
        });
    },
    fetchCount(tool, toolId) {
      let selectedProvince = "";
      let selectedDistrict = "";
      if (tool === "patientTool") {
        selectedProvince = this.patientToolProvince;
        selectedDistrict = this.patientToolDistrict;
      } else if (tool === "communityBased") {
        selectedProvince = this.communityBasedProvince;
        selectedDistrict = this.communityBasedDistrict;
      } else if (tool === "healthFacility") {
        selectedProvince = this.healthFacilityProvince;
        selectedDistrict = this.healthFacilityDistrict;
      } else if (tool === "serviceProvider") {
        selectedProvince = this.serviceProviderProvince;
        selectedDistrict = this.serviceProviderDistrict;
      }
      const requestData = {
        tool_id: toolId,
      };
      if (selectedProvince) {
        requestData.province_id = selectedProvince;
      }
      if (selectedDistrict) {
        requestData.district_id = selectedDistrict;
      }
      axios
        .post("admin/count-participants", requestData)
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            if (tool === "patientTool") {
              this.patientToolCount = response.data.data.count;
            } else if (tool === "communityBased") {
              this.communityBasedCount = response.data.data.count;
            } else if (tool === "healthFacility") {
              this.healthFacilityCount = response.data.data.count;
            } else if (tool === "serviceProvider") {
              this.serviceProviderCount = response.data.data.count;
            }
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.error("Error fetching participant count:", error.response.data);
          } else {
            console.error("Error fetching participant count:", error.message);
          }
        });
    },
    truncateText(text, length) {
      return text.length > length ? text.slice(0, length) : text;
    },
    showFullQuestion(question) {
      this.fullQuestion = question.question;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.fullQuestion = "";
    },
    removeDistrict(districtId) {
      this.selectedDistricts = this.selectedDistricts.filter((id) => id !== districtId);
    },
    removeTool(toolId) {
      this.selectedTools = this.selectedTools.filter((id) => id !== toolId);
    },
    removeCondition(conditionId) {
      this.selectedConditions = this.selectedConditions.filter((id) => id !== conditionId);
    },
    removeQuestion(questionId) {
      this.selectedQuestions = this.selectedQuestions.filter((id) => id !== questionId);
    },
    removeUrgency(urgency) {
      this.selectedUrgency = this.selectedUrgency.filter((item) => item !== urgency);
    },
    removeStatus(status) {
      this.selectedStatus = this.selectedStatus.filter((item) => item !== status);
    },
    resetSelections() {
      this.selectedDistricts = [];
      this.selectedTools = [];
      this.selectedConditions = [];
      this.selectedQuestions = [];
      this.selectedUrgency = [];
      this.selectedStatus = [];
    },
    openNameModal() {
      this.isNameModalOpen = true;
    },
    closeNameModal() {
      this.isNameModalOpen = false;
      this.dashboardName = "";
    },
    saveDashboard() {
      this.showChartModal = false;
      this.openNameModal();
    },
    saveDashboardWithName() {
      if (!this.dashboardName) {
        Toast.error("Please enter a name for the dashboard.");
        return;
      }
      if (!this.selectedTools.length || !this.selectedConditions.length || !this.selectedDistricts.length) {
        Toast.error("Please select tools, conditions, and districts before saving the dashboard.");
        return;
      }
      const dashboardData = {
        user_id: this.userId,
        name: this.dashboardName,
        tool_id: this.selectedTools,
        condition_id: this.selectedConditions,
        district_id: this.selectedDistricts,
        questions: this.selectedQuestions,
        urgencies: this.selectedUrgency,
        statuses: this.selectedStatus,
        charts_data: this.chartsData,
        chart_sizes: this.chartSizes, // Save sizes
        chart_types: this.chartTypes, // Save types
        type: 'graph'
      };
      axios
        .post("/save-dashboard", dashboardData)
        .then((response) => {
          if (response.data.status) {
            // this.fetchDashboards();
            this.closeNameModal();
            Toast.success("Dashboard saved successfully");
            window.location.reload();
            // window.location.href = window.location.href;
            
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error saving dashboard:", error);
        });
    },
    fetchDashboards() {
      axios
        .get(`/dashboards?user_id=${this.userId}`)
        .then((response) => {
          if (response.data.status) {
            this.dashboards = response.data.data;
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching dashboards:", error);
        });
    },
    loadDashboard(dashboardId) {
      axios
        .get(`/dashboard/${dashboardId}`)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data;
            this.selectedTools = data.tool_id;
            this.selectedConditions = data.condition_id;
            this.selectedDistricts = data.district_id;
            this.selectedQuestions = data.questions;
            this.selectedUrgency = data.urgencies;
            this.selectedStatus = data.statuses;
            this.chartsData = data.charts_data;
            this.chartSizes = data.chart_sizes || {};
            this.chartTypes = data.chart_types || {};
            this.activeTab = "Dashboard-" + dashboardId;
            this.selectedType = data.type || 'table';
            this.destroyCharts();
            if (this.selectedType === 'graph') {
              this.renderCharts();  // Simple graph
            } else if (this.selectedType === 'hc_graph') {
              this.hcGraphData = data.charts_data.questions;
              this.renderHealthCenterCharts();  // More complex hc_graph
            } else {
              this.tableData = data.charts_data.questions;
              this.healthCenterNames = this.extractHealthCenterNames(this.tableData);
            }
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error loading dashboard:", error);
        });
    },
    previewCharts() {
      this.createCharts();
      this.showChartModal = true;
    },
    closeChartModal() {
      this.showChartModal = false;
    },
    createCharts() {
      const requestData = {
        tool_id: this.selectedTools,
        condition_id: this.selectedConditions,
        district_id: this.selectedDistricts,
        questions: this.selectedQuestions,
        urgencies: this.selectedUrgency,
        statuses: this.selectedStatus,
      };
      axios
        .post("/questions-with-answers-chart", requestData)
        .then((response) => {
          if (response.data.status) {
            this.chartsData.questions = response.data.data.questions;
            this.chartsData.urgencies = response.data.data.urgencies;
            this.chartsData.statuses = response.data.data.statuses;
            this.renderCharts();
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error creating charts:", error);
        });
    },
    destroyCharts() {
      Object.keys(this.chartInstances).forEach((key) => {
        if (this.chartInstances[key]) {
          this.chartInstances[key].destroy();
          delete this.chartInstances[key];
        }
      });
    },
    renderCharts() {
      this.$nextTick(() => {
        const colors = [
          "rgba(52, 152, 219, 1)", // Blue
          "rgba(46, 204, 113, 1)", // Green
          "rgba(230, 126, 34, 1)", // Orange
          "rgba(241, 196, 15, 1)", // Yellow
          "rgba(155, 89, 182, 1)", // Purple
          "rgba(231, 76, 60, 1)", // Red
          "rgba(232, 67, 147, 1)", // Pink
          "rgba(26, 188, 156, 1)", // Teal,
        ];

        // Render question charts
        this.chartsData.questions.forEach((question) => {
          const ctx = document.getElementById(`chart-${question.id}`).getContext("2d");
          if (this.chartInstances[`chart-${question.id}`]) {
            this.chartInstances[`chart-${question.id}`].destroy();
          }
          this.chartInstances[`chart-${question.id}`] = new Chart(ctx, {
            type: this.chartTypes[question.id] || "bar",
            data: {
              labels: question.responses.map((response) => response.response),
              datasets: [
                {
                  label: question.question,
                  data: question.responses.map((response) => response.count),
                  backgroundColor: question.responses.map((_, idx) => colors[idx % colors.length]),
                  borderColor: question.responses.map((_, idx) => colors[idx % colors.length]),
                  borderWidth: 1,
                },
              ],
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  grid: {
                    display: false,
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                },
              },
              maintainAspectRatio: false,
              responsive: true,
            },
          });
        });

        // Render urgencies chart
        const urgenciesCtx = document.getElementById("chart-urgencies").getContext("2d");
        if (this.chartInstances["chart-urgencies"]) {
          this.chartInstances["chart-urgencies"].destroy();
        }
        this.chartInstances["chart-urgencies"] = new Chart(urgenciesCtx, {
          type: this.chartTypes.urgencies || this.urgenciesChartType || "bar",
          data: {
            labels: Object.keys(this.chartsData.urgencies),
            datasets: [
              {
                label: "Urgencies",
                data: Object.values(this.chartsData.urgencies),
                backgroundColor: Object.values(this.chartsData.urgencies).map(
                  (_, index) => colors[index % colors.length]
                ),
                borderColor: Object.values(this.chartsData.urgencies).map(
                  (_, index) => colors[index % colors.length]
                ),
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  display: false,
                },
              },
              x: {
                grid: {
                  display: false,
                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
          },
        });

        // Render statuses chart
        const statusesCtx = document.getElementById("chart-statuses").getContext("2d");
        if (this.chartInstances["chart-statuses"]) {
          this.chartInstances["chart-statuses"].destroy();
        }
        this.chartInstances["chart-statuses"] = new Chart(statusesCtx, {
          type: this.chartTypes.statuses || this.statusesChartType || "bar",
          data: {
            labels: Object.keys(this.chartsData.statuses),
            datasets: [
              {
                label: "Statuses",
                data: Object.values(this.chartsData.statuses),
                backgroundColor: Object.values(this.chartsData.statuses).map(
                  (_, index) => colors[index % colors.length]
                ),
                borderColor: Object.values(this.chartsData.statuses).map(
                  (_, index) => colors[index % colors.length]
                ),
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  display: false,
                },
              },
              x: {
                grid: {
                  display: false,
                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
          },
        });
      });
    },
    renderHealthCenterCharts() {
      this.$nextTick(() => {
        const colors = [
          "rgba(52, 152, 219, 1)", // Blue
          "rgba(46, 204, 113, 1)", // Green
          "rgba(230, 126, 34, 1)", // Orange
          "rgba(241, 196, 15, 1)", // Yellow
        ];

        this.hcGraphData.forEach((question, index) => {
          const chartId = `chart-${index}`; // Use the index as part of the chart ID
          const ctx = document.getElementById(chartId);
          console.log(ctx); // Check if ctx is correctly retrieved

          if (ctx && ctx.getContext) {
            const chartCtx = ctx.getContext("2d");

            // Destroy the existing chart instance if it exists
            if (this.chartInstances[chartId]) {
              this.chartInstances[chartId].destroy();
            }

            const labels = question.responses.map(response => response.response);
            const datasets = [];

            const healthCenters = [...new Set(question.responses.flatMap(response =>
              response.health_centers.map(center => center.health_center)
            ))];

            healthCenters.forEach((healthCenter, hIndex) => {
              const data = question.responses.map(response => {
                const center = response.health_centers.find(center => center.health_center === healthCenter);
                return center ? center.count : 0;
              });

              datasets.push({
                label: healthCenter,
                data: data,
                backgroundColor: colors[hIndex % colors.length],
              });
            });

            this.chartInstances[chartId] = new Chart(chartCtx, {
              type: "bar",
              data: {
                labels,
                datasets,
              },
              options: {
                plugins: {
                  title: {
                    display: true,
                    text: question.question,
                    font: {
                      size: 16,
                      weight: 'bold'
                    },
                    padding: {
                      top: 10,
                      bottom: 20
                    }
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                    stacked: false,
                  },
                  y: {
                    beginAtZero: true,
                    stacked: false,
                    grid: {
                      display: false,
                    },
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
              },
            });
          } else {
            console.error(`Canvas element with ID ${chartId} was not found.`);
          }
        });
      });
    },


    extractHealthCenterNames(data) {
      const healthCenterNamesSet = new Set();
      data.forEach(question => {
        question.responses.forEach(response => {
          response.health_centers.forEach(center => {
            healthCenterNamesSet.add(center.health_center);
          });
        });
      });
      return Array.from(healthCenterNamesSet);
    },
    getResponseRowspan(responses) {
      return responses.reduce((total, response) => total + response.health_centers.length, 0);
    },
    getHealthCenterCount(centers, centerName, currentCenterName) {
      if (currentCenterName === centerName) {
        const center = centers.find(center => center.health_center === centerName);
        return center ? center.count : 0;
      }
      return 0;
    },
    getTotalRowspan(responses) {
      return responses.reduce((total, response) => total + response.health_centers.length, 0);
    },
    confirmDelete(dashboardId) {
      if (confirm("Are you sure you want to delete this dashboard?")) {
        this.deleteDashboard(dashboardId);
      }
    },
    deleteDashboard(dashboardId) {
      axios
        .delete(`/dashboard/${dashboardId}`)
        .then((response) => {
          if (response.data.status) {
            this.fetchDashboards();
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error deleting dashboard:", error);
        });
    },
    onResizing(chartId, { width, height }) {
      console.log('Resizing in progress for chart:', chartId, 'Current size:', width, height);
    },
    onResizeStop(chartId, { width, height }) {
      console.log('Resize stopped for chart:', chartId, 'New size:', width, height);
      if (width && height) {
        this.chartSizes[chartId] = { width, height };
        console.log('Updated chartSizes:', JSON.stringify(this.chartSizes, null, 2));
      } else {
        console.error('Invalid width or height:', { width, height });
      }
    },
    getChartSize(chartId, dimension, defaultValue) {
      if (!this.chartSizes[chartId]) {
        this.chartSizes[chartId] = { width: 600, height: 300 };
      }
      const size = this.chartSizes[chartId][dimension];
      console.log(`Getting chart size for ${chartId} - ${dimension}:`, size);
      return size || defaultValue;
    },
    saveChartSettings() {
      console.log('Saving chart sizes:', this.chartSizes);
      axios
        .post(`/dashboard/${this.activeTab.split('-')[1]}/save-settings`, {
          chart_sizes: this.chartSizes,
          chart_types: this.chartTypes,
        })
        .then((response) => {
          if (response.data.status) {
            alert("Settings saved successfully");
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error saving settings:", error);
        });
    },
    downloadExcel() {
      const ws = XLSX.utils.aoa_to_sheet(this.prepareExcelData());
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Health Center Report");

      // Apply cell merges
      const merges = this.getMerges();
      if (merges.length) {
        ws['!merges'] = merges;
      }

      XLSX.writeFile(wb, "health_center_report.xlsx");
    },
    prepareExcelData() {
      const data = [];

      // Headers
      const headers = ["Condition", "Question", "Answers", "District", "Area", ...this.healthCenterNames];
      data.push(headers);

      // Data Rows
      this.tableData.forEach(question => {
        question.responses.forEach((response, rIndex) => {
          response.health_centers.forEach((center, cIndex) => {
            const row = [];
            if (rIndex === 0 && cIndex === 0) {
              row.push(question.condition);
              row.push(question.question);
            } else {
              row.push("");
              row.push("");
            }
            if (cIndex === 0) {
              row.push(response.response);
            } else {
              row.push("");
            }
            row.push(center.district);
            row.push(center.location);
            this.healthCenterNames.forEach(name => {
              row.push(this.getHealthCenterCount(response.health_centers, name, center.health_center));
            });
            data.push(row);
          });
        });
      });

      return data;
    },
    getMerges() {
      const merges = [];
      let conditionStart = 1, questionStart = 1, responseStart = 1;

      this.tableData.forEach(question => {
        const responseCount = this.getTotalRowspan(question.responses);
        merges.push({ s: { r: conditionStart, c: 0 }, e: { r: conditionStart + responseCount - 1, c: 0 } });
        merges.push({ s: { r: questionStart, c: 1 }, e: { r: questionStart + responseCount - 1, c: 1 } });

        question.responses.forEach(response => {
          merges.push({ s: { r: responseStart, c: 2 }, e: { r: responseStart + response.health_centers.length - 1, c: 2 } });
          responseStart += response.health_centers.length;
        });

        conditionStart += responseCount;
        questionStart += responseCount;
      });

      return merges;
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "hasPermission",
    }),
    selectedDistrictsDetails() {
      return this.districts.filter((district) => this.selectedDistricts.includes(district.id));
    },
    selectedToolsDetails() {
      return this.tools.filter((tool) => this.selectedTools.includes(tool.id));
    },
    selectedConditionsDetails() {
      return this.conditions.filter((condition) => this.selectedConditions.includes(condition.id));
    },
    selectedQuestionsDetails() {
      return this.questions.filter((question) => this.selectedQuestions.includes(question.id));
    },
  },
  watch: {
    selectedTools: "fetchQuestions",
    selectedConditions: "fetchQuestions",
    selectedDistricts: "fetchQuestions",
  },
};
</script>


<style lang="scss" scoped>
:root {
  --color-blue: #4A90E2;
  --color-red: #FF6F61;
  --color-green: #50E3C2;
  --color-yellow: #F8E71C;
  --color-orange: #F5A623;
  --color-purple: #9013FE;
  --color-pink: #FF8C94;
  --color-teal: #4A9ABF;
}

.location-item {
  width: 100%;

  button {
    width: 100%;
    padding: 10px;
  }

  .location-details {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
}

.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 206px;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-radius: 8px;
  margin: 2px 0;
  height: 48px;
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.dashboard-container {
  height: 100%;
  padding: 36px 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .row {
    display: flex;
    gap: 24px;

    .col {
      flex: 1;

      h1 {
        color: var(--blue-original-2);
        font-size: 24px;
        line-height: 20px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .card {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        border: 1px solid #D0D5DD;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .card-content {
          width: 100%;

          .c1 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .main-count {
              background-color: var(--blue-light);
              padding: 6px 12px;
              border-radius: 30px;
            }

            h3 {
              font-size: 14px;
            }

            p {
              font-weight: regular;
            }
          }

          h3 {
            margin: 0;
            font-size: 1.2em;
            color: var(--blue-500);
            font-weight: regular;
          }

          .count {
            font-size: 14px;
            font-weight: 400;
            color: var(--blue-original);
          }

          select {
            margin-top: 10px;
            padding: 5px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            width: 100%;
            font-size: 1em;
            outline: none;
            padding-left: 16px;
            background-color: transparent;

            &:focus {
              border: 1px solid var(--blue-original);
            }
          }
        }
      }
    }

    .usersCol {
      width: 455px;
    }

    .WeeklyDiagnosis {
      width: 100%;
    }

    .tabs {
      width: 70%;
    }

    .questionCol {
      width: 60%;
      margin-top: 24px;
    }

    .conditonAnalytics {}
  }

  .nav-tabs {
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 8px;
    background-color: rgba(33, 72, 192, 10%);
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    .nav-item {
      margin-bottom: -1px;
      font-size: 16px;
      font-weight: 600;
      padding: 8px 16px;
      text-align: center;
      // flex: 1;

      .nav-link {
        display: block;
        padding: 10px 15px;
        color: #555;
        text-decoration: none;
        cursor: pointer;
        font-weight: 400;
        white-space: nowrap;

        &:hover {
          color: #000;
          background-color: #e9ecef;
        }

        &.active {
          color: var(--blue-original);
          background-color: #fff;
          border-radius: 8px;
          font-weight: bold;
          border: 2px solid #e4e7ec;

          a {
            li {
              padding: 12px;
              border-bottom: 4px solid #2148C0;
            }
          }
        }
      }
    }
  }

  .nav-tabs::-webkit-scrollbar {
    height: 8px;
  }

  .nav-tabs::-webkit-scrollbar-thumb {
    background-color: rgba(33, 72, 192, 0.4);
    border-radius: 4px;
  }

  .nav-tabs .nav-item {
    margin-bottom: -1px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 16px;
    text-align: center;
    flex-shrink: 0;
  }

  .nav-tabs .nav-link {
    display: block;
    padding: 10px 15px;
    color: #555;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    white-space: nowrap;
  }

  .nav-tabs .nav-link:hover {
    color: #000;
    background-color: #e9ecef;
  }

  .nav-tabs .nav-link.active {
    color: var(--blue-original);
    background-color: #fff;
    border-radius: 8px;
    font-weight: bold;
    border: 2px solid #e4e7ec;
  }

  .tab-content {
    padding-top: 20px;
    border-top: none;
  }

  .cards-row {
    margin-bottom: 30px;
  }

  .sidebar {
    width: 245px;
    padding: 8px;
    background-color: #f9fafb;
    border-radius: 8px;
    transition: width 0.3s ease;

    &.sidebar-wide {
      width: 545px;
    }

    .card {
      text-align: left;

      h3 {
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;

          a {
            color: #007bff;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          input[type="checkbox"] {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin-bottom: 10px;
    align-items: center;
    padding-left: 6px;

    .selected-item {
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 5px;
      margin-bottom: 10px;
      padding-left: 6px;
      border: 1px solid #E4E7EC;

      button {
        background: none;
        border: none;
        color: red;
        font-size: 16px;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 6px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .content {
    width: 100%;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .btn-cancel {
    background-color: transparent;
    color: #ca150c;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: #ca150c41;
    }
  }

  .btn-create {
    background-color: #2fab4f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .btn-save {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .btn-cancel:hover,
  .btn-create:hover,
  .btn-save:hover {
    opacity: 0.8;
  }

  .dashboard-item {
    display: flex;
    align-items: center;
  }

  .btn-delete {
    color: #ff0d0d;
    border: none;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }

  .chart-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .canvas-container {
      position: relative;

      .vue-draggable-resizable {
        background-color: #fff;
      }

      .card {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        border: 1px solid #D0D5DD;
        height: 100%;
        width: 100%;
        overflow: hidden;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .chart-wrapper {
          width: 100%;
          height: 100%;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;

          canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }

  .chart-header {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    padding: 5px;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;

    &:hover,
    &:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 200ms ease;

      &.save {
        background-color: var(--blue-500);
        color: var(--white);

        &:hover {
          background-color: var(--blue-600);
        }
      }

      &.cancel {
        background-color: var(--gray);
        color: var(--white);

        &:hover {
          background-color: #504e74;
        }
      }
    }
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--gray);
    border-radius: 5px;
  }
}

.container {
  position: relative;
  width: 100%;

  svg {
    background-color: white;
    position: absolute;
    top: 60%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

select {
  height: 40px;
  padding-left: 15px;
  font-size: 16px;
  border: 1.9px solid lightgray;
  border-radius: 4px;
  color: #39325a;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: transparent;
}

.sub-tabs {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 12px;

  .active {
    li {
      padding: 12px;
      border-bottom: 4px solid #2148C0;
    }

    .sub-link {
      display: flex;
      align-items: center;
      gap: 10px;

      .locationIcon {
        path {
          fill: #1C274C;
          fill-opacity: 1;
        }
      }

      .toolsIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .urgencyIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .conditionIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .questionsIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .statusIcon {
        path {
          fill: #1C274C;
          fill-opacity: 1;
        }
      }

      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0A2472;
      }
    }
  }

  .sub-link {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #d7d8da;
    }
  }

  a {
    text-decoration: none;

    li {
      padding: 12px;
      border-bottom: 4px solid transparent;
    }
  }

  li {
    min-width: 160px;
  }
}

.province {
  width: 100%;
  margin-bottom: 10px;

  .accordion {
    width: 100%;
    padding: 12px;
    background: #f9fafb;
    color: #444;
    cursor: pointer;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    text-align: left;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e9ecef;
    }

    .icon {
      display: flex;
      align-items: center;
      margin-left: auto;

      svg {
        transition: transform 0.3s ease;
      }
    }
  }

  .panel {
    padding: 3px 0px 40px 25px;
    background-color: #f9fafb;
    border-radius: 8px;
    margin-top: 8px;

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 8px 0;
        display: flex;
        align-items: center;

        input[type="checkbox"] {
          margin-right: 10px;
        }
      }

      .loading {
        text-align: center;
        padding: 10px;
        color: #888;
      }
    }
  }
}

.dropdown-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  margin: 0;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 25px;
  // left: 0;
  right: 30px;
  z-index: 100;
  border-radius: 4px;
  padding: 10px;

  a {
    padding: 5px 10px;
    text-decoration: none;
    color: #444;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.save-change-button {
  height: 48px;
  width: 151px;
  border-radius: 8px;
  border: none;
  background-color: rgb(0, 106, 245);
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: 200ms ease;
  margin-left: 85%;
  margin-top: -35px;
  margin-bottom: 8px;
}

.save-change-button:hover {
  background-color: rgb(4, 91, 206);
}

.save-change-button button {
  height: 48px;
  width: 151px;
  border-radius: 8px;
  border: none;
  background-color: rgb(0, 106, 245);
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: 200ms ease;
}

.customize-tab {
  margin: 20px;
}

.customize-tab-question {
  width: 100%;
}

h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--color-blue);
}

.styled-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .styled-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    input[type="checkbox"] {
      margin-right: 10px;
      transform: scale(1.2);
    }

    label {
      font-size: 1em;
      color: #333;
      cursor: pointer;
    }
  }

  .styled-list-item-question {
    padding: 20px;
    font-size: 1.1em;
    width: 90%;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.download-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin-left: -88%;
  margin-bottom: 12px;
}

.download-button:hover {
  background-color: #0056b3;
}

.download-button:active {
  background-color: #004085;
}

.chart-preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.chart-preview-modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 1200px;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 80vh;
}

.chart-preview-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }
}

.chart-preview-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.chart-preview-canvas-container {
  position: relative;
  padding-top: 56.25%;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.chart-preview-canvas-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.chart-preview-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-preview {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
}

.chart-preview-modal-content {
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}


.chart-preview-container {

  .chartjs-render-monitor,
  .chartjs-label,
  .chartjs-legend-text,
  .chartjs-title,
  .chartjs-xAxis,
  .chartjs-yAxis {
    color: black !important;
  }
}

.chart-preview-container {
  padding-right: 10px;
}

.chart-preview-canvas-container {
  canvas {
    color: black !important;
  }
}
</style>
