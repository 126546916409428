<template>
  <div class="card">
    <div class="card-header">
      <h3>Urgency Analysis Per Tool and Location</h3>
      <div class="legends">
        <div class="item">
          <div class="box low"></div>
          <div class="label">Low</div>
        </div>
        <div class="item">
          <div class="box medium"></div>
          <div class="label">Medium</div>
        </div>
        <div class="item">
          <div class="box high"></div>
          <div class="label">High</div>
        </div>
      </div>
    </div>
    <div class="chart-container" id="chart-container">
      <canvas id="UrgencyChart"></canvas>
    </div>

    <div class="dropdown-container">

      <div class="container">
        <select v-model="selectedTool" @change="fetchChartData">
          <option disabled value="">Select Tool</option>
          <option value="1" v-if="hasPermission('tools','patient_tool')">Patient Tool</option>
          <option value="2" v-if="hasPermission('tools','health_facility_tool')">Health Facility</option>
          <option value="3" v-if="hasPermission('tools','community_based_tool')">Community Based</option>
          <option value="4" v-if="hasPermission('tools','observation_tool')">Observation Tool</option>
        </select>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <div class="container">

        <select v-model="selectedProvince" @change="fetchDistricts">
          <option disabled value="">Select Province</option>
          <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
        </select>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <div class="container">

        <select v-model="selectedDistrict" @change="fetchChartData">
          <option disabled value="">Select District</option>
          <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
        </select>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      provinces: [],
      districts: [],
      selectedTool: "",
      selectedProvince: "",
      selectedDistrict: "",
      chart: null,
    };
  },
  mounted() {
    this.fetchProvinces();
    this.fetchChartData(); // Fetch data when the component is mounted
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  methods: {
    async fetchProvinces() {
      try {
        const response = await axios.get('/province');
        this.provinces = response.data.data;
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(`/districts/${this.selectedProvince}`);
        this.districts = response.data.data;
        this.fetchChartData(); // Fetch data when a province is selected
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchChartData() {
      try {
        const response = await axios.get('/urgency-analysis', {
          params: {
            tool_id: this.selectedTool,
            province_id: this.selectedProvince,
            district_id: this.selectedDistrict,
          },
        });

        const chartData = response.data.data;
        console.log('Fetched chart data:', chartData);

        // Determine the structure of the data
        const firstKey = Object.keys(chartData)[0];
        if (typeof chartData[firstKey].low !== 'undefined') {
          // Grouped by conditions
          this.updateChartWithConditions(chartData);
        } else {
          // Grouped by tools
          this.updateChartWithTools(chartData);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    },
    updateChartWithTools(data) {
      const toolNames = {
        '1': 'Patient Tool',
        '2': 'Health Facility',
        '3': 'Community Based',
        '4': 'Observation Tool',
      };

      const labels = Object.keys(data).map(toolId => toolNames[toolId] || 'Unknown Tool');
      const lowData = [];
      const mediumData = [];
      const highData = [];

      Object.keys(data).forEach(toolId => {
        const tool = data[toolId];
        lowData.push(tool.low);
        mediumData.push(tool.medium);
        highData.push(tool.high);
      });

      this.updateChart(labels, lowData, mediumData, highData);
    },
    updateChartWithConditions(data) {
      const labels = Object.keys(data);
      const lowData = labels.map(label => data[label].low);
      const mediumData = labels.map(label => data[label].medium);
      const highData = labels.map(label => data[label].high);

      this.updateChart(labels, lowData, mediumData, highData);
    },
    updateChart(labels, lowData, mediumData, highData) {
      if (this.chart) {
        this.chart.destroy(); // Destroy the existing chart to update it
      }

      const canvasContainer = document.getElementById("chart-container");
      const canvas = document.getElementById("UrgencyChart");


      // Function to set canvas size based on container size
      function setCanvasSize() {
        canvas.width = canvasContainer.clientWidth;
        canvas.height = canvasContainer.clientHeight;
      }

      // Initial setup
      setCanvasSize();





      const ctx = document.getElementById("UrgencyChart").getContext("2d");

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Low",
              data: lowData,
              backgroundColor: "#31B063",
            },
            {
              label: "Medium",
              data: mediumData,
              backgroundColor: "#ECE637",
            },
            {
              label: "High",
              data: highData,
              backgroundColor: "#D32F2F",
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            }
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 345px;
  box-shadow: 0 0 14px rgb(206, 201, 201);
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;

}

.card-header {
  width: 100%;

  h3 {
    font-weight: 400;
    font-size: 16;
    line-height: 24px;
  }

  display: flex;
  flex-direction: column;
  align-items: start;
  display: flex;
  gap: 12px;
  padding-bottom: 24px;

  .legends {
    display: flex;
    align-items: center;
    gap: 13px;
    justify-content: right;

    width: 100%;

    .item {
      display: flex;
      align-items: center;
      gap: 6px;

      .label {
        color: #667085;
      }

    }

    .box {
      width: 8px;
      height: 8px;
      border-radius: 50px;
    }

    .low {
      background-color: #31B063;
    }

    .medium {
      background-color: #ECE637;
    }

    .high {
      background-color: #D32F2F;
    }
  }
}

.chart-container {
  width: 100%;
  height: 150px;
}

.dropdown-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;



  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    appearance: none;
    background-color: transparent;
    color: #39325a;
  }
}

.container {
  position: relative;
  width: 100%;



  svg {
    position: absolute;
    top: 60%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

select {

  margin-top: 10px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  width: 100%;
  font-size: 1em;
  outline: none;
  padding-left: 16px;
  background-color: transparent;
  color: #39325a;

}
</style>
